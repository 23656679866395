import React from 'react'
import './termsStyle.css'

const Terms = () => {
  return (
    <div>
       <div className='main-terms'>
        <h1>TERMS AND CONDITIONS FOR THE USE OF Wide Logistics And ONLINE SERVICE WEBSITE OR PLATFORM</h1>
        <p>These user terms and conditions (hereinafter referred to as the "User Terms") apply to any use of Wide Logistics And ONLINE SERVICE website (hereinafter referred to as the "Website or platform").</p>

        <p>These User Terms are deemed to include all other operating rules, policies, and guidelines that are referred to herein or that we may otherwise publish on the Website (as such, rules, policies and guidelines may be amended from time to time), including without limitation</p>

        <p>By using the Website, you accept to be subject to the User Terms, including the User Guidelines. If you do not accept these User Terms, you are not permitted to use the Website and are kindly requested not to use the Website any further. The registration as a user requires your express acceptance of these User Terms.</p>

        <h2>THE SERVICES ON WEBSITE OR PLATFORM</h2>

        <h3>1. REGISTERED USER OR SERVICE PROVIDER</h3>
        <p>1.1 Wide Logistics grants you the non-exclusive, non-transferable, revocable, limited right to access and use the Website. In order to gain access and use of the Website, you must create an Account or profile and register as a user (hereinafter referred to as "Registered User"). Not only that also you can use the website without creating an account.</p>
        <p>1.2 You are only permitted to register one profile per person on the Website. The profile is personal and you must not transfer it to others.</p>
        <p>1.3 In order to become a Registered User, you need a password. You choose your own password, which must be used, with your email address when logging onto the Website. Alternatively, we may send the password to you. The password is personal and you must not transfer it or in other ways make it available to others. It is your responsibility to ensure that the password does not fall into the hands of a third party. If you become aware that the password is or may have been compromised, you are obligated to inform us hereof .We can and will change the password if there is a risk that the password has been compromised or is used in violation of the User Terms.</p>
        <p>1.4 During the registration process, you must choose a username. The username may be shown on the Website whenever you write or comment on reviews should you choose to post them publicly (not anonymous) or produce user-generated content (see 2.1 below) on the Website. Therefore, you must consider whether you wish to use a username from which others can identify you. The username must not (i) be offensive or in other ways insulting, (ii) contain the terms "Guest", "Admin", "*.dk", "*.com", etc. or (iii) contain characteristics which belong to a third party, including names of famous persons, or personal names to which you do not own the rights. You represent and warrant that your username does not infringe on any rights (including any intellectual property rights) belonging to any third party and/or pertaining to the User Terms.</p>
        <p>1.5 Changes to the username may only be made by us. If you want to change your username, please contact us at infowikilix2022@gmail.com.</p>
        <p>1.6 We are entitled at any time, without notice and without prejudice, to delete, suspend or change your profile in the event of your violation or suspected violation of these User Terms or applicable law. When deleting your profile, you will no longer have access to services on the Website, which require your registration and/or login as a Registered User. When deleting your profile, we reserve the right to delete the user-generated content (see 2.1) you have made on the Website.</p>
        <p>1.7 Furthermore, we reserve the right, at any time and without notice or explanation, to delete your profile and user-generated content (see 2.1).</p>
        <p>1.8 You are not permitted to gain access or attempt to gain access to the parts of the Website requiring user registration if you are not a Registered User.</p>

        <h3>2. Customer access the publications or posts from Registered Users or service providers.</h3>
        <p>2.1 You hereby grant us the worldwide, non-exclusive, perpetual, irrevocable, royalty-free right and license to publish, display, and commercially transactions any material, information, notifications, reviews, articles or other types of communication (hereinafter referred to as the "CUSTOMER ACCESS” which you create on the Website as a Registered User.</p>
        <p>2.2 You are solely responsible and legally liable for the contents or products you publish on the Website.</p>
        <p>2.3 You represent and warrant that contents or products posted on the Website is correct and true (where they state facts) or genuinely held (where they state opinions).</p>
        <p>2.4 Contents or products must relate to a manufactured company or organization from which you have purchased or can otherwise document using the company's or organization's products or services.</p>
        <p>2.5 You may not publish contents or products regarding companies to which you have personal or professional relations without permissions.</p>
        <p>2.6 You must not, and must not allow any third party to, publish contents or products on the website which is of a marketing explosion in nature or destructing purposes, some products which are not allowed to be posted are substandard products, harmful products, dangerous to health of the public, military weapons, drugs such as cocaine, and other drugs that do not permitted by international laws.</p>
        <p>2.7 You represent and warrant in every context that the contents or products lawful and in compliance with the User Terms. If Wide Logistics receives notice or otherwise becomes aware that contents or products posted violates current legislation and/or the User Terms, we may delete the contents or products without any notice, and we - dependent on the character of the violation - may inform the violated party and/or the authorities of the violation. Our right to delete will not be conditioned on an explanation.</p>
        <p>2.8 You hereby grant us the right to initiate and take any legal actions, which we deem necessary in case of infringement of your contents or products posted.</p>
        <p>You must indemnify and hold us harmless for any claims which may be made against us as a consequence of the your violation of the User Terms or current legislation or loss due to third party claims against us resulting from your contents or products posted.</p>
        <p>2.9 We may at any time request information about your contents or products from you including documentation supporting the information included in the content or products, e.g. documentation evidencing that the products has a certificate of conformity, payment slip of the products if reseller is not a firm manufactured a product.</p>
        <p>2.10. Agree to use this website or platform you agree to accept service fees as it is on financial accounts provided, if you refuse or delay to pay willingly the fees will be increased in accordance to the time taken for delay.</p>

        <h2>GENERAL TERMS</h2>

        <h3>3. Rights:</h3>
        <p>3.1 The Website and the services we offer via the Website, including all underlying technology and intellectual property rights embodied therein, are and remain our sole and exclusive property, and no license or any other right is granted to any such underlying technology. If you provide feedback, ideas or suggestions regarding the Website or the services offered on the Website ("Feedback"), we are free to fully exploit such Feedback.</p>
        <p>3.2 The content on the Website, including but not limited to the intellectual property rights, text, characteristics, graphics, icons, photos, calculations, references and software is or will be our property or the property of a third party (other than the Registered User) and is protected. and applicable international legislation, including without limitation applicable copyright and trademark laws.</p>
        <p>3.3 Unauthorized copying, distribution, presentation or other use of the Website or part hereof is a violation of RWANDAN law and may thus result in civil and/or criminal penalties.</p>
        <p>3.4 To the fullest extent permitted by law, the rights to free use of the contents or products are transferred to us irrevocably, without any time limitation and without territorial limitations, by submitting the contents or products to us.</p>
        <p>3.5 Downloading and other digital copying of the content on the Website or parts hereof are only permitted for personal non-commercial use unless otherwise agreed with us in writing or allowed under applicable mandatory law.</p>
        <p>3.6 All company names, trademarks and other business characteristics on the Website are or will be our property or the property of a third party and must only be used for business purposes upon prior approval from us or the third party owner, respectively.</p>

        <h3>4. Personal data</h3>
        <p>4.1 We perform different types of processing of personal data in connection with the use of the Website. Our processing of personal data takes place under observance of our Privacy Policy.</p>
        <p>By accepting these User Terms, you confirm to have read and accepted our Privacy Policy.</p>
        <p>Each User agrees, in fulfilling its respective obligations under these General User Terms, to comply with all applicable data privacy legislation and with the terms of Privacy Policy our which describes how we collect, use and safeguard your personal information (Information) to administer your Services and Site experience. By communicating with us via e-mail or by submitting content, including reviews- or any other transactional exchange on our Sites, you consent to receive e-mail or other electronic communications from our Affiliates and us. We also take Security seriously and endeavor through the use of industry-standard technical, physical and operational controls to ensure that our Services and Sites are compliant with all applicable laws.</p>

        <h3>5. Disclaimers</h3>
        <p>5.1 The Website, Content And Services Offered On The Website Are Provided 'As Is' And As Available Without Representations Or Warranties Of Any Kind. Wide Logistics Expressly Disclaims Any And All Warranties, Express, Implied Or Statutory, Including Without Limitation Any Warranties Of Title Non-Infringement, Merchantability Or Fitness For A Particular Purpose. The Website And Services May Be Modified, Updated, Interrupted, Suspended Or Discontinued At Any Time Without Notice Or Liability.</p>
        <p>5.2 We make no representations or warranties with respect to any contents or products published on the Website. Notwithstanding the foregoing, Wide Logistics may at all times investigate and edit (including anonymizing) Contents or products, e.g. if such actions are (i) prompted by third-party requests, (ii) required under applicable law or (iii) necessary for the contents or products compliance with our User Guidelines.</p>
        <p>5.3 We disclaim all liability for the content or products. Our non-liability applies, without limitation, to any contents or products, including the contents or products, which have been edited by us (see 5.2). We are not liable for any links to third party websites in the contents or products, including for the content of the page to which the contents or products links.</p>
        <p>5.4 Recommendations, reviews, comments, etc. of specific companies, services, e-businesses, etc. on the Website are provided by Registered Users and are not endorsements made by us. We disclaim all liability for the content of the Website. The use of our services is in any respect the sole responsibility of the Registered Users. We cannot be held liable for the availability of the Website.</p>
        <p>5.5 We disclaim all liability for the content or products that sold or bought between sellers and buyers and then one party not fulfilling the contract agreed by two parties. If service providers give services and the servicers offer or receivers receive services provided by service provider then refuse to pay service fees vice versa in that case these two parties will settle this conflicts without interventions of the Wide Logistics.</p>

        <h3>6. Limitation of liability</h3>
        <p>6.1 we shall in no case be held liable, whether in contract, tort (including negligence) or otherwise for damages for the use of the website, even if we have been advised of the possibility of such damages, including (i) loss of profits, contracts, turnover, business, business opportunity, loss or corruption of data or recovery of data, goodwill, security breach resulting from a failure of third party telecommunications and/or the internet, anticipated savings or revenue (regardless of whether any of these are direct, indirect or consequential) (ii) any loss or damage arising in connection with liabilities to third parties (whether direct, indirect or consequential) or (iii) any indirect, special, punitive, incidental or consequential loss or damage whatsoever. some states and other jurisdictions do not allow the exclusion or limitation of liability for incidental or consequential damages, so the above limitations and exclusions may not apply to you.</p>
        <p>6.2 our total aggregate liability, including without limitation liability for breach of contract, misrepresentation (whether tortious or statutory), tort (including negligence), breach of statutory duty or otherwise, arising from or in connection with the user terms, the website or our services will never for any and all actionable circumstances exceed one hundred dollars ($100.00). you further agree that no claims or actions arising out of, or related to, the use of our website or services or these user terms may be brought by you more than one (1) year after the actionable. Event. Nothing in the user terms excludes or limits either party's liability for matters, which cannot be excluded or limited under applicable law.</p>

        <h3>7. Other User Terms</h3>
        <p>7.1 We may at any time, in our sole discretion, revise or change these User Terms. We may at any time, in our discretion and without notice, close, change or reorganize the Website. As a Registered User, you accept to be covered by the at all times current User Terms. Any revision or change of the User Terms will be stated on the Website. The Registered Users agree that the continued use of the Website after any posted modified version of the User Terms is an acceptance of the modified User Terms.</p>
        <p>7.2 Should any of these User Terms be regarded as unlawful or without effect and therefore not to be enforced, this will not have any effect on the applicability and enforcement of the remaining part of the User Terms.</p>

        <h3>8. Term and termination</h3>
        <p>8.1 We may terminate your right to access and use the services offered on the Website at any time for any reason or no reason without liability. If we do so, or if you elect to delete your profile, any rights granted to you herein will immediately cease. Sections 2-10 will survive any termination of the User Terms.</p>

        <h3>9. Applicable law and venue</h3>
        <p>9.1 The User Terms and the relationship between you and Wide Logistics are governed by the laws of the government of RWANDA, without regard to the conflicts of law provisions thereof. Any disputes must be brought exclusively in the state or federal courts located in kigali, and the parties hereby consent to the jurisdiction of such courts.</p>

        <h3>10. Copyright dispute policy</h3>
        <p>10.1 It is Wide Logistics's policy to (i) block access to or remove material that it believes in good faith to be copyrighted material that has been illegally copied and distributed by any of our advertisers, affiliates, content providers or members or users ;and (ii) remove and discontinue service to repeat offenders. Wide Logistics has adopted a policy toward copyright infringement in accordance with the Digital Millennium Copyright Act.</p>

        <h3>11. Miscellaneous</h3>
        <p>11.1 You acknowledge and agree that these User Terms constitute the complete and exclusive agreement between you and Wide Logistics concerning your use of the Website and supersede and govern all prior proposals, agreements or other communications.</p>
      </div>
    </div>
  )
}

export default Terms
