import React, { useState, useEffect } from 'react';
import './UserStyle.css';
import axios from 'axios';
import UserInfo from './UserInfo';
import { useNavigate } from 'react-router-dom';
import UserProducts from './UserProducts'; // Import the updated UserProducts component
import EmptyProducts from '../../Other/EmptyProducts';
import { toast } from 'react-toastify';

const User = () => {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [LoggedInUser, setLoggedInUser] = useState("");
  const [loggedInUserId, setLoggedInUserId] = useState();
  const [error, setError] = useState(false);
  const [products, setProducts] = useState([]);
  axios.defaults.withCredentials = true;

  useEffect(() => {
    axios.get('https://wl.widelogistics.online/api/check-user')
      .then(res => {
        if (res.data.ResultStatus) {
          setIsLoggedIn(true);
          setLoggedInUser(res.data.ResultMsg.userName);
          setLoggedInUserId(res.data.ResultMsg.userId);
        } else {
          console.log(res.data.ResultMsg);
          setIsLoggedIn(false);
        }
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    axios.get(`https://wl.widelogistics.online/api/products/by/${loggedInUserId}`)
      .then(res => {
        if (res.data.ResultStatus) {
          if (res.data.ResultMsg.length > 0) {
            setProducts(res.data.ResultMsg);
          } else {
            setError(true);
          }
        }
      })
      .catch(err => console.log(err));
  }, [loggedInUserId]);

  useEffect(() => {
    console.log(products);
  }, [products]);

  function logoutUser() {
    axios.get('https://wl.widelogistics.online/api/logout')
      .then(res => {
        if (res.data.ResultStatus) {
          setIsLoggedIn(false);
          navigate('/login-signup');
        }
      })
      .catch(err => console.log(err));
  }

  function showSuccess()
    {
        toast.success("Product Successfully Deleted");
    }
  function deleteItem(itemId)
  {
    axios.get(`https://wl.widelogistics.online/api/products/delete/${itemId}`)
        .then(res => {
            if (res.data.ResultStatus) {
                showSuccess()
                const updatedProducts = products.filter((product) => product.asset_id !== itemId);
                setProducts(updatedProducts);
            } else {
                console.log(res.data.ResultMsg);
            }
        })
        .catch(err => {console.log(err);});
  }

  return (
    <div className='user-container'>
      {isLoggedIn && products && <UserProducts deleteItem={deleteItem} products={products} error={error} />}
      <div className="right user-div">
        {isLoggedIn && <button className="confirm" onClick={() => { navigate('/upload'); }}>Add new</button>}
        {isLoggedIn ? <UserInfo logoutUser={logoutUser} userNames={LoggedInUser} /> : "You are not Logged in!"}
      </div>
    </div>
  )
}

export default User;
