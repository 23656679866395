import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkLogin } from './assets/AdminApi';
import "./Reports.css";
import axios from 'axios';

const AddTeam = () => {

  const [isLoading, setIsLoading] = useState(false)
  axios.defaults.withCredentials = true
  const navigate = useNavigate()
  useEffect(() => {
    const checkUserLogin = async () => {
      const isLoggedIn = await checkLogin();
      if (!isLoggedIn) {
        navigate('/AdminLogin');
      }
    };
    checkUserLogin();
  }, [navigate]);

  const [userData, setUserData] = useState({
    names: '',
    role: '',
    email: '',
    whatsapp: '+2507',
    instagram: '',
    phone: '+2507',
    facebook: '',
    userImg: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  function handleChangeImg(e) {
    if (e.target.files.length > 0) {
      setUserData({ ...userData, userImg: e.target.files[0] });
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    

    if(userData.names.length < 1 || userData.role.length < 1 || userData.email.length < 1 || userData.whatsapp.length < 1 || userData.instagram.length < 1 || userData.phone.length < 1 || userData.facebook.length <1 || userData.userImg.length < 1)
    {
      toast.error('Please fill all the fields');
      return;
    }
    if(userData.whatsapp.length !== 13)
    {
      toast.error('Please enter a valid whatsapp number');
      return;
    }
    if(userData.phone.length !== 13)
    {
      toast.error('Please enter a valid phone number');
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('names', userData.names);
    formData.append('role', userData.role);
    formData.append('email', userData.email);
    formData.append('whatsapp', userData.whatsapp);
    formData.append('instagram', userData.instagram);
    formData.append('phone', userData.phone);
    formData.append('facebook', userData.facebook);
    formData.append('userImg', userData.userImg);

    axios.post('https://wl.widelogistics.online/api/admin/addTeam', formData)
      .then((res) => {
        setIsLoading(false)
        setUserData({
          names: '',
          role: '',
          email: '',
          whatsapp: '',
          instagram: '',
          phone: '',
          facebook: '',
          userImg: ''
        })
        if (res.data.ResultStatus) {
          notify()
        }
        else {
          console.log(res.data.ResultMsg)
        }
      })
      .catch(err => { setIsLoading(false) ;console.log(err) })
  };
  const notify = () => {
    toast.success('Team Member Added');
  };
  return (
    <div className="registration-form">
      <h2>Register Team Member</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="names">Names:</label>
          <input
            type="text"
            id="names"
            name="names"
            value={userData.names}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="role">Role:</label>
          <input
            type="text"
            id="role"
            name="role"
            value={userData.role}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="whatsapp">Whatsapp Number:</label>
          <input
            type="text"
            id="whatsapp"
            name="whatsapp"
            value={userData.whatsapp}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="instagram">Instagram UserName:</label>
          <input
            type="text"
            id="instagram"
            name="instagram"
            value={userData.instagram}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number:</label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={userData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="facebook">Facebook:</label>
          <input
            type="text"
            id="facebook"
            name="facebook"
            value={userData.facebook}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="userImg">Image: {userData.userImg ? userData.userImg.name : "No File Chosen"}</label>
          <input
            type="file"
            id="userImg"
            name="userImg"
            onChange={handleChangeImg}
            required
          />
        </div>
        <button type="submit" disabled={isLoading}>{ isLoading ? "Adding..." : "Add"}</button>
        <ToastContainer />
      </form>
    </div>
  );
};

export default AddTeam;
