import React from 'react'
import 
 {BsFillBellFill, BsFillEnvelopeFill, BsPersonCircle, BsSearch, BsJustify}
 from 'react-icons/bs'
 import SearchBar from './SearchBar.jsx';
  import { useState } from 'react';
import { Link } from 'react-router-dom';
  // import 'font-awesome/css/font-awesome.min.css';

function Header({OpenSidebar}) {
  const [showForm, setShowForm] = useState(false);
  const handleIconClick = () => {
    setShowForm(!showForm);
  };

  return (
    <header className='header'>
        <div className='menu-icon'>
            <BsJustify className='icon' onClick={OpenSidebar}/>
        </div>
        <div className='header-left'>
            {/* <BsSearch  className='icon'/> */}
            {/* <SearchBar /> */}
        </div>
        <div className='header-right'>
            {/* <BsFillBellFill className='icon'/>
            <BsFillEnvelopeFill className='icon'/> */}
            <BsPersonCircle  onClick={handleIconClick} id='person'/>
      {showForm && (
        <div >
          <nav className='hidden-profiles'>
          
          <BsPersonCircle id="my-icon" />
          
            <span id='user-name'><b>Username: </b>Uwihanganye Edison</span>
            <ul id='my-list'>
            <li id='sec-list'><Link to='#'>My settings</Link></li>
            <li id='sec-list'><Link to='#' id='log-out'>Logout</Link></li>

            </ul>
          </nav>
        </div>
      )}
        </div>
    </header>
  )
}

export default Header
