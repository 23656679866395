import React, { useEffect, useState} from 'react';
import './login.css';
import newuser_img from './assets/newuser.png'
import user_img from './assets/user.jpg'
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom'

const SignUp = () => {

  const [showPopup, setShowPopup] = useState(true);
  const [activeTab, setActiveTab] = useState('login');

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const switchToLogin = () => {
    setActiveTab('login');
  };

  const switchToSignup = () => {
    setActiveTab('signup');
  };

  return (
    <div>
      {/* <a onClick={openPopup}>sign up  here</a> */}
      {showPopup && (
        <div className="popup-container" id="popupContainer">
          <div className="popup-content">
            <div className="tabs">
              <button className="close-button" id="closePopup" onClick={closePopup}>
                &#10005;
              </button>
              <button
                className={`tab-button ${activeTab === 'login' ? 'active' : ''}`}
                onClick={switchToLogin}
                style={{ color: 'black'}}
              >
                Login
              </button>
              <button
                className={`tab-button ${activeTab === 'signup' ? 'active' : ''}`}
                onClick={switchToSignup}
                style={{ color: 'black', fontSize: '20px', paddingLeft: 10 }}
              >
                SignUp
              </button>
            </div>
            {activeTab === 'login' ? (
              <LoginForm />
            ) : (
              <SignUpForm switchToLogin={switchToLogin} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

function LoginForm() {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: "",
    password: ""
  });
  const [err, setErr] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = (e) =>
  {
    e.preventDefault()
    setIsLoading(true)
    if(loginData.email == "")
    {
      setErr("Enter Email please");
      setIsLoading(false)
      return;
    }
    if(loginData.password == "")
    {
      setErr("Enter Password please");
      setIsLoading(false)
      return;
    }
    axios.post("https://wl.widelogistics.online/api/login", loginData)
    .then(res => {
      setIsLoading(false)
      if(res.data.ResultStatus)
      {
        navigate('/user');
      }
      else
      {
        setErr(res.data.ResultMsg);
      }
    })
    .catch(err => {
      setIsLoading(false)
      setErr("External Error: " + err)
    })
  }
  return (
    <form className="form" onSubmit={handleLogin}>
      <div className="icon">
        <img src={user_img} alt="User Icon" />
      </div>
      <p className='error-container'>{err && err}</p>
      <h2>Login</h2>
      <input type="text" placeholder="Email" onChange={(e)=>{setLoginData({...loginData, email: e.target.value})}}/>
      <input type="password" placeholder="Password" onChange={(e)=>{setLoginData({...loginData, password: e.target.value})}}/>
      <Link to="javascript:void(0)" className="forgot-password">
        Forgot Password?
      </Link>
      <button className="login-button" disabled={isLoading}>{isLoading? "Logging in..." : "Login"}</button>
    </form>
  );
}

function SignUpForm(props) {

  const [errorInSignUp, setErrorInSignUp] = useState();
  const [isSigningUp, setIsSigningUp] = useState(false);

  const [signupData, setSignupData] = useState({
    firstName: "",
    otherName: "",
    email: "",
    phoneNumber: "",
    password: "",
    passwordConfirm: ""
  });

  function isPasswordStrong(givenPassword)
  {
    return true
  }
  function isEmailValid(givenEmail)
  {
    return true
  }
  function isPhoneValid(givenPhone)
  {
    return true
  }

  const handleSignup = (e) =>
  {
    e.preventDefault();
    setIsSigningUp(true)
    if(signupData.firstName == "" || signupData.otherName == "" || signupData.email == "" || signupData.phoneNumber == "" || signupData.password == "" || signupData.passwordConfirm == "")
    {
      setErrorInSignUp("Fill all Fields please!")
      setIsSigningUp(false)
      return;
    }
    if(signupData.password != signupData.passwordConfirm)
    {
      setErrorInSignUp("Provided Passwords do not matches!")
      setIsSigningUp(false)
      return;
    }
    if(!isPasswordStrong(signupData.password))
    {
      setErrorInSignUp("Provided Password is weak!")
      setIsSigningUp(false)
      return;
    }
    if(!isEmailValid(signupData.email))
    {
      setErrorInSignUp("Provided Email is not valid!")
      setIsSigningUp(false)
      return;
    }
    if(!isPhoneValid(signupData.phoneNumber))
    {
      setErrorInSignUp("Provided Phone Number is not valid!")
      setIsSigningUp(false)
      return;
    }
    axios.post("https://wl.widelogistics.online/api/create", signupData)
    .then(res => {
      if(res.data.ResultStatus)
      {
        props.switchToLogin();
      }
      else
      {
        setErrorInSignUp(res.data.ResultMsg)
      }
    })
    .catch(err => {alert("Error: " + err)})
  }

  return (
    <form className="form" onSubmit={handleSignup}>
      <div className="icon">
        <img src={newuser_img} alt="User Icon" />
      </div>
      <p className='error-container'>{errorInSignUp && errorInSignUp}</p>
      <h2>Sign Up</h2>
      <input type="text" value={signupData.firstName}  placeholder="First Name" onChange={e => setSignupData({...signupData, firstName: e.target.value})} />
      <input type="text" placeholder="Last Name" onChange={e => setSignupData({...signupData, otherName: e.target.value})}/>
      <input type="email" placeholder="Email" onChange={e => setSignupData({...signupData, email: e.target.value})}/>
      <input type="text" placeholder="Phone number" onChange={e => setSignupData({...signupData, phoneNumber: e.target.value})}/>
      <input type="password" placeholder="Password" onChange={e => setSignupData({...signupData, password: e.target.value})}/>
      <input type="password" placeholder="Confirm Password" onChange={e => setSignupData({...signupData, passwordConfirm: e.target.value})}/>
      <button className="signup-button" disabled={isSigningUp}>{ isSigningUp? 'Signing Up...' : 'Sign Up' }</button>
    </form>
  );
}
export default SignUp;
