import React from 'react'
import 
{BsCart3, BsGrid1X2Fill, BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, 
  BsListCheck, BsMenuButtonWideFill, BsFillGearFill}
 from 'react-icons/bs'
import { Link } from 'react-router-dom'

function Sidebar({openSidebarToggle, OpenSidebar}) {
  return (
    <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive": ""}>
        <div className='sidebar-title'>
            <div className='sidebar-brand'>
                {/* <BsCart3  className='icon_header'/>  */}Wide Logistics And Online Services
            </div>
            <span className='icon close_icon' onClick={OpenSidebar}>X</span>
        </div>

        

        <ul className='sidebar-list'>
            
            <Link to="/admin" style={{color: 'black'}}>
                <li className='sidebar-list-item'>
                    <BsGrid1X2Fill className='icon'/> Dashboard
                </li>
            </Link>

            <Link to="/admin/AddProduct" style={{color: 'black'}}>
                <li className='sidebar-list-item'>
                <BsFillArchiveFill className='icon'/> Add Products
                </li>
            </Link>
            
            <Link to="/admin/Users" style={{color: 'black'}}>
                <li className='sidebar-list-item'>
                    <BsFillArchiveFill className='icon'/> Users
                </li>
            </Link>
            
            <Link to="/admin/AllProducts" style={{color: 'black'}}>
                <li className='sidebar-list-item'>
                    <BsFillArchiveFill className='icon'/> All Products
                </li>
            </Link>
            
            <Link to="/admin/AddTeam" style={{color: 'black'}}>
                <li className='sidebar-list-item'>
                    <BsFillArchiveFill className='icon'/> Add Team
                </li>
            </Link>
            
            <Link to="/admin/AllContacts" style={{color: 'black'}}>
                <li className='sidebar-list-item'>
                    <BsFillArchiveFill className='icon'/> All Messages
                </li>
            </Link>
            
            
        </ul>
    </aside>
  )
}

export default Sidebar
