import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppAdmin from './Admin/AppAdmin';
import Products from './Admin/AddProducts'
import App from './App';
import TestimonialsPage from "./Components/pages/news/testimolial/testmolial";
import EventsPage from "./Components/pages/news/events/events";
import Upload from "./Components/pages/upload/Upload";
import PaymentPage from "./Components/pages/upload/PaymentPage";
import Terms from "./Components/pages/terms/Terms";
import User from "./Components/pages/user/User";
import SignUp from "./Components/pages/signup/SignUp";
import Contact from "./Components/pages/contact/Contact";
import News from "./Components/pages/news/News";
import Property from "./Components/pages/property/Property";
import ProcurementPage from "./Components/pages/services/Procurement";
import ServicePage from "./Components/pages/services/Shipping";
import About from "./Components/pages/about/About";
import ForRent from "./Components/pages/rent/ForRent";
import ForBuy from "./Components/pages/buy/ForBuy";
import HomeAdmin from "./Admin/Home";
import Home from "./Components/pages/home/Home";
import Login from "./Admin/components/login/Login";
import Users from "./Admin/components/Users";
import AddTeam from "./Admin/AddTeam";
import AllProducts from "./Admin/components/AllProducts";
import Search from "./Components/pages/search/Search";
import AllContacts from "./Admin/components/AllContacts";
import AddProduct from "./Admin/AddProducts";
function MainApp() {
  return (
    <Router>
        <Routes>
            <Route path="/" element={<App/>}>
                <Route path="" element={<Home />}/>
                <Route path="for-buy" element={<ForBuy/>}/>
                <Route path="for-rent" element={<ForRent/>}/>
                <Route path="about" element={<About/>}/>
                <Route path="shipping" element={<ServicePage />}/>
                <Route path="procurement" element={<ProcurementPage />}/>
                <Route path="property" element={<Property/>}/>
                <Route path="news" element={<News/>}/>
                <Route path="contact-us" element={<Contact/>}/>
                <Route path="login-signup" element={<SignUp/>}/>
                <Route path="user" element={<User/>}/>
                <Route path="terms" element={<Terms/>}/>
                <Route path="pay" element={<PaymentPage /> }/>
                <Route path="upload" element={<Upload />}/>
                <Route path="events" element={<EventsPage />}/>
                <Route path="testimonies" element={<TestimonialsPage />}/>
                <Route path="search" element={<Search />}/>
                <Route path='AdminLogin' element={<Login /> } />
            </Route>
            <Route path="/admin" element={<AppAdmin/>}>
                <Route path='' element={<HomeAdmin /> } />
                {/* <Route path='login' element={<Login /> } /> */}
                <Route path='AddProduct' element={ <AddProduct/>} />
                <Route path='AddTeam' element={ <AddTeam />} />
                <Route path='AllProducts' element={ <AllProducts />} />
                <Route path='Users' element={ <Users />} />
                <Route path='AllContacts' element={ <AllContacts />} />
            </Route>
        </Routes>
    </Router>
  );
}
export default MainApp;