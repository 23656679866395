import React from 'react'
import { Link } from 'react-router-dom';

const NavUser = (props) => {
    const isLoggedIn = props.isLoggedIn;
    const loggedInUserNames = props.loggedInUserNames;
  return (
    <div>
      {isLoggedIn ? <LoggedInUserNav loggedInUserNames={loggedInUserNames} /> : <NotLoggedInUserNav />}
    </div>
  )
}

function LoggedInUserNav(props)
{
    const loggedInUserNames = props.loggedInUserNames;
    return(
        <div>
            <p><Link to="user">{loggedInUserNames}, Logged in. &nbsp;&nbsp;&nbsp;</Link></p>
            
        </div>
    )
}

function NotLoggedInUserNav()
{
    return(
        <div>
            <Link to="login-signup">Login</Link>
        </div>
    )
}

export default NavUser
