// Create a context
import React, { createContext } from 'react';
import useLocalState from './Components/MyHooks/UseLocalState';

const WikilixContext = createContext();

const WikilixContextProvider = ({ children }) => {
  // const [currentCountry, setCurrentCountry] = useState('Rwanda');
  const [currentCountry, setCurrentCountry] = useLocalState('currentCountry', 'Rwanda');
  const [isUserLoggedIn, setIsUserLoggedIn] = useLocalState("isUserLoggedIn", false)
  const [loggedInUser, setLoggedInUser] = useLocalState("currentUser", '');

  return (
    <WikilixContext.Provider value={{ currentCountry, setCurrentCountry, isUserLoggedIn, setIsUserLoggedIn , loggedInUser, setLoggedInUser}}>
      {children}
    </WikilixContext.Provider>
  );
};

export { WikilixContext, WikilixContextProvider };
