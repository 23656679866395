import React, { useState } from 'react'
import Item from '../item/Item'
import '../assets/ProductsStyle.css'
import underline_Img from '../assets/underline.png'
import left_arrow from './assets/left-arrow.png'
import right_arrow from './assets/right-arrow.png'

const Items = (props) => {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const productCategory = props.productCategory;
    const pageName = props.page;
    const products = props.products;
    const deleteItem = props.deleteItem;

  return (
    <div className='computers-for-sell for-sell'>
        <div className='for-sell-title'>
        </div>
        <div className='for-sell-flex'>
        <div className='for-sell-btn-left'><img src={left_arrow} className='for-sale-icon'/></div>
            <div className='for-sell-items'>
            {products.map( product => <Item  productCategory={productCategory} itemData={product} pageName={pageName} deleteItem={deleteItem}/>)}
            </div>
            <div className='for-sell-btn-right'><img src={right_arrow} className='for-sale-icon'/></div>
        </div>
        </div>
  )
}

export default Items
