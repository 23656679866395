import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './add-product.css';
import { checkLogin } from './assets/AdminApi';

function AddProduct() {
  const navigate = useNavigate();
  const [err, setErr] = useState("");
  const [isUploading, setIsUploading] = useState(false);


  
  axios.defaults.withCredentials = true
  useEffect(() => {
    const checkUserLogin = async () => {
      const isLoggedIn = await checkLogin();
      if (!isLoggedIn) {
        navigate('/AdminLogin');
      }
    };
    checkUserLogin();
  }, [navigate]);



  const [productData, setProductData] = useState({
    supplierFirstName: "",
    supplierOtherNames: "",
    supplierEmail: "",
    supplierTel: "",
    assetType: "",
    assetName: "",
    assetPrice: "",
    assetCurrency: "",
    assetInfo: "",
    assetMandatoryImage: "",
    assetAdditionalImage: [],
    assetCountry: "",
    assetCity: "",
    assetUserId: -1
  })

  const handleUpload = (e) =>{
    setIsUploading(true);
    e.preventDefault();
    if(productData.supplierFirstName.trim() === "" || productData.supplierOtherNames.trim() === "" || productData.supplierEmail.trim() === "" || productData.supplierTel.trim() === "" || productData.assetName.trim() === "" || productData.assetPrice.trim() === "" || productData.assetInfo.trim() === "" || productData.assetCity.trim() === "")
    {
      setErr("Fill all fields");
      setIsUploading(false);
      return;
    }
    if(productData.assetMandatoryImage.length === 0)
    {
      setErr("Add Mandatory Image please!");
      setIsUploading(false);
      return;
    }

    const formData = new FormData()
    formData.append('assetMandatoryImage', productData.assetMandatoryImage);
    formData.append('supplierFirstName', productData.supplierFirstName);
    formData.append('supplierOtherNames', productData.supplierOtherNames);
    formData.append('supplierEmail', productData.supplierEmail);
    formData.append('supplierTel', productData.supplierTel);
    formData.append('assetType', productData.assetType);
    formData.append('assetName', productData.assetName);
    formData.append('assetPrice', productData.assetPrice);
    formData.append('assetCurrency', productData.assetCurrency);
    formData.append('assetInfo', productData.assetInfo);
    formData.append('assetCountry', productData.assetCountry);
    formData.append('assetCity', productData.assetCity);
    formData.append('assetUserId', productData.assetUserId);
    //call API
    axios.defaults.withCredentials = true;
    axios.post("https://wl.widelogistics.online/api/upload", formData)
    .then(res => {
      if(res.data.ResultStatus)
      {
        navigate('/admin/AllProducts')
      }
      else
      {
        setErr(res.data.ResultMsg)
      }
    })
    .catch(err => {setErr("Error: " + err)})
  }

  const handleMandatoryFileChange = (event) => {
    if (event.target.files.length > 0) {
      setProductData({...productData, assetMandatoryImage: event.target.files[0]})
    }
  };

  const handleAdditionalFileChange = (event) => {
    if (event.target.files.length > 0) {
      setProductData({...productData, assetAdditionalImage: Array.from(event.target.files)})
    }
  };


  return (
    <div className="upload-admin-body">
      <div className="upload-admin-header">
        <span className="page_title"> ADMIN Upload Products</span> <br/>
        <span className='error-container' style={{color: 'red'}}>{err && err}</span>
      </div>
      <div>
      <form className="upload-admin-form" onSubmit={handleUpload} style={{width: '100%'}}>
        
          <div>
            <ul>
              <h3> <i className="fa fa-info-circle"></i> OWNER DATA:</h3>
              <li><input type="text" name="firstname" id="firstname" placeholder="first name" onChange={(e)=>{setProductData({...productData, supplierFirstName: e.target.value})}} /></li>
              <li><input type="text" name="othernames" id="" placeholder="other names" onChange={(e)=>{setProductData({...productData, supplierOtherNames: e.target.value})}}/></li>
              <li> <input type="text" name="email" id="email" placeholder="email" onChange={(e)=>{setProductData({...productData, supplierEmail: e.target.value})}}/></li>
              <li><input type="tel" name="tel" id="tel" placeholder="tel" onChange={(e)=>{setProductData({...productData, supplierTel: e.target.value})}}/></li>
            </ul>
          </div>
          <div>
            <ul>
              <h3><i className="fa fa-file"></i> Asset info</h3>
              <li>
                <label htmlFor="assetType"> Choose asset type:</label> <br />
                <select name="assetType" id="assetTtype" onChange={(e)=>{setProductData({...productData, assetType: e.target.value})}}>
                  <option value="houde">house</option>
                  <option value="land">land</option>
                  <option value="">elctronic</option>
                  <option value="audi">living room</option>
                </select>
              </li>
              <li><input type="text" name="assetName" id="assetName" placeholder="asset Name" onChange={(e)=>{setProductData({...productData, assetName: e.target.value})}}/></li>
              <li><input type="number" name="price" id="price" placeholder="price" onChange={(e)=>{setProductData({...productData, assetPrice: e.target.value})}}/></li>
              <li>
                <label htmlFor="currency">choose currency:</label>
                <select name="currency" id="currency" onChange={(e)=>{setProductData({...productData, assetCurrency: e.target.value})}}>
                  <option value="RWF">Rwf</option>
                  <option value="$">$</option>
                </select>
              </li>
              <li>
                <label htmlFor="additionalinfo"> addition Information</label> <br />
                <textarea name="additionalinfo" id="additionalinfo" cols="20" rows="3" placeholder="add more description" onChange={(e)=>{setProductData({...productData, assetInfo: e.target.value})}}></textarea>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <h3><i className="fa fa-solid fa-image"></i> upload photo</h3>
              <div className="upload-admin-container">
                <label className="upload-admin-label" htmlFor="mandatory_image">Choose Mandatory Image</label>
                <input type="file" className="upload-admin-input" name="mandatory_image" id="mandatory_image" onChange={handleMandatoryFileChange} />
                <p className="upload-admin-text">Selected File: {productData.assetMandatoryImage ? productData.assetMandatoryImage.name : 'No file chosen'}</p>
              </div>
              <div className="upload-admin-container">
                <label className="upload-admin-label" htmlFor="additional_image">Choose Additional Images</label>
                <input type="file" className="upload-admin-input" name="additional_image" id="additional_image" onChange={handleAdditionalFileChange} multiple />
                <p className="upload-admin-text">Selected Files: {productData.assetAdditionalImage.length === 0 ? 'No files chosen' : productData.assetAdditionalImage.map(file => file.name).join(', ')}</p>
              </div>
            </ul>
          </div>
          <div>
            <ul>
              <li><h3> <i className="fa fa-location-arrow"></i> Add location</h3></li>
              <li>
                <label htmlFor="location">choose where product located</label> <br />
                <select name="location" id="location" onChange={(e)=>{setProductData({...productData, assetCountry: e.target.value})}}>
                  <option value="RWANDA">RWANDA</option>
                  <option value="TANZANIA">TANZANIYA</option>
                  <option value="BURUNDI">BURUNDI</option>
                  <option value="DRCONGO"> DR CONGO</option>
                  <option value="KENYA">KENYA</option>
                  <option value="GABON">GABON</option>
                </select>
              </li>
              <li>
                <label htmlFor="city">Enter city:</label> <br />
                <input type="text" name="city" id="city" placeholder="city" onChange={(e)=>{setProductData({...productData, assetCity: e.target.value})}}/>
              </li>
            </ul>
          </div>
          <button disabled={isUploading}>{isUploading? "Uploading..." : "UPLOAD"}</button>     </form>
       </div>
    </div>
  );
}

export default AddProduct;
