import React from 'react'
import { FaFacebook, FaTwitter, FaInstagram, FaPinterest, FaTiktok, FaYoutube, FaEnvelope, FaPhone, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const TeamMember = ({teamName, teamUserImg, teamRole, teamEmail, teamWhatsapp, teamInstagram, teamPhone, teamFacebook}) => {
  const userImg = `https://wl.widelogistics.online/api/asset_images/${teamUserImg}`;

  return (
    <div class="col-md-4 col-sm-6 col-xs-6 team-grids">
        <div class="team-agile-img">
            <Link to="#"><img src={userImg} alt="img"/></Link>
            <div class="view-caption">
                <div class="w3ls-info">
                    <h4>{teamName}</h4>
                    <h6>{teamRole}</h6>
                </div>
                <ul>
                <li><Link to={`https://wa.me/${teamWhatsapp}`}><FaWhatsapp/> </Link></li>
                <li><Link to={`mailto:${teamEmail}`}><FaEnvelope /></Link></li>
                <li><Link to={`https://www.instagram.com/${teamInstagram}`}><FaInstagram /></Link></li>
                <li><Link to={`tel:${teamPhone}`}><FaPhone /></Link></li>
                <li><Link to={teamFacebook}><FaFacebook /></Link></li>

                </ul>
            </div>
        </div>
    </div>
  )
}

export default TeamMember
