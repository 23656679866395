import React from 'react';
import './payment.css'; 
import momoImg from './assets/momo.png';
import airtelmoneyImg from './assets/airtelmoney.png';
import visaImg from './assets/visa.png';
import paypalImg from './assets/paypal.png';
import { useNavigate } from 'react-router-dom';


function PaymentPage() {
  const navigate = useNavigate();
  return (
    <div className='payment-container-parent'>

    
    <div className="payment-container">
      <h2><i className="fas fa-money-check-dollar"></i> Select Payment Option</h2>
      <div className="payment-options">
        <div className="select">
          <label>
            <input type="radio" name="paymentOption" value="momo" defaultChecked /> Momo pay
          </label>
          <label>
            <input type="radio" name="paymentOption" value="airtel" /> Airtel
          </label>
          <label>
            <input type="radio" name="paymentOption" value="visa" /> VISA
          </label>
          <label>
            <input type="radio" name="paymentOption" value="paypal" /> PayPal
          </label>
        </div>
        <div className="payment-icons">
          <img src={momoImg} alt="Momo Icon" />
          <img src={airtelmoneyImg} alt="Airtel Icon" />
          <img src={visaImg} alt="VISA Icon" />
          <img src={paypalImg} alt="PayPal Icon" />
        </div>
      </div>
      <div className="payment-methods">
        <h2><i className="fas fa-money-check"></i> Payment Method</h2>
        <form className="payment-form">
          <div className="interface-header">
            <h3><i className="fas fa-credit-card"></i> Pay With Momo</h3>
            <img src={momoImg} alt="Momo Icon" className="icon" />
          </div>
          <div className="form-group">
            <label htmlFor="cardNumber">Enter your mobile Number</label>
            <input type="text" id="cardNumber" defaultValue="+250 78" />
          </div>
          <div>
            <button type="button" className="back-to-upload" onClick={()=>{navigate('/upload')}}>Back</button>
            <button className="pay-upload-button" onClick={()=>{navigate('/user')}}>Pay & Upload</button>
          </div>
        </form>
      </div>
    </div>
    </div>
  );
}

export default PaymentPage;
