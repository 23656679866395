import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { checkLogin } from '../assets/AdminApi'

const AllProducts = () => {

    const [products, setProducts] = useState([])

    axios.defaults.withCredentials = true
    const navigate = useNavigate()
    useEffect(() => {
      const checkUserLogin = async () => {
        const isLoggedIn = await checkLogin();
        if (!isLoggedIn) {
          navigate('/AdminLogin');
        }
      };
      checkUserLogin();
    }, [navigate]);


    useEffect(()=>{
        //fetch all  users
        const linkToAPI = `https://wl.widelogistics.online/api/admin/products`;
        axios.get(linkToAPI)
            .then(res=>
            {
                if(res.data.ResultStatus)
                {
                    setProducts(res.data.ResultMsg)
                    console.log(res.data.ResultMsg)
                    
                }
                else
                {
                    console.log('Error in Fetching Products ' + res.data.ResultMsg);
                }
            })
        .catch(err => console.log(err));
      }, [])
  return (
    <div style={{paddingLeft: 50, paddingRight: 10, paddingTop: 50}}>
       <h1>All Products</h1>
       {products && <Product assets={products}/>}

    </div>
  )
}

function Product({assets})
{
    return (
        <table class="table table-hover table-striped ">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Asset Name</th>
                    <th scope="col">Asset Price</th>
                    <th scope="col">Asset Type</th>
                    <th scope="col">Supplier Names</th>
                    <th scope="col">Supplier Email</th>
                    <th scope="col">Supplier Phone</th>
                    <th scope="col">Asset Info</th>
                    <th scope="col">Asset Country</th>
                </tr>
            </thead>
            <tbody>
           { assets.map(asset => <Tr asset={asset}/>)}
                
            </tbody>
        </table>
    )
}

function Tr({asset})
{
    return (
        <tr>
            <th scope="row">{asset.asset_id}</th>
            <td>{asset.assetName}</td>
            <td>{asset.assetPrice}Rwf</td>
            <td>{asset.assetType}</td>
            <td>{asset.supplierFirstName +" "+ asset.supplierOtherNames}</td>
            <td>{asset.supplierEmail}</td>
            <td>{asset.supplierTel}</td>
            <td>{asset.assetInfo}</td>
            <td>{asset.assetCountry}</td>
        </tr>
    )
}

export default AllProducts
