import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './upload.css';

function Upload() {
  const navigate = useNavigate();

  //check if is to manage
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let itemId = 0
  itemId = searchParams.get('item');
  const [oldItemId, setOldItemId] = useState(itemId);
  const [isUpdate, setIsUpdate] = useState( itemId ? true : false);

  // alert(isUpdate)


  // function fetchItemData(givenItemId) {
  //   axios.get(`https://wl.widelogistics.online/api/products/id/${givenItemId}`)
  //     .then(res => {
  //       if (res.data.ResultStatus) {
  //         if (res.data.ResultMsg.length > 0) {
  //           const newProduct = res.data.ResultMsg[0];
  //           setProductData({
  //             ...productData,
  //             supplierFirstName: newProduct.supplierFirstName,
  //             supplierOtherNames: newProduct.supplierOtherNames,
  //             supplierEmail: newProduct.supplierEmail,
  //             supplierTel: newProduct.supplierTel,
  //             assetType: newProduct.assetType,
  //             assetName: newProduct.assetName,
  //             assetPrice: newProduct.assetPrice,
  //             assetCurrency: newProduct.assetCurrency,
  //             assetInfo: newProduct.assetInfo,
  //             assetCountry: newProduct.assetCountry,
  //             assetCity: newProduct.assetCity,
  //             assetUserId: newProduct.assetUserId
  //           });

  //         }
  //         else {
  //           console.log("No Product")
  //         }
  //       }
  //       else {
  //         console.log(res.data.ResultMsg)
  //       }
  //     })
  //     .catch(err => console.log(err));
  // }

  const [err, setErr] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const [productData, setProductData] = useState({
    supplierFirstName: "",
    supplierOtherNames: "",
    supplierEmail: "",
    supplierTel: "",
    assetType: "food",
    assetName: "",
    assetPrice: "",
    assetCurrency: "Rwf",
    assetInfo: "",
    assetMandatoryImage: "",
    assetAdditionalImage: [],
    assetCountry: "Rwanda",
    assetCity: "",
    assetUserId: -1
  })

  // if (isUpdate) {
  //   fetchItemData(oldItemId)
  // }

  const handleUpload = (e) => {
    setIsUploading(true);
    e.preventDefault();
    if (productData.supplierFirstName.trim() === "" || productData.supplierOtherNames.trim() === "" || productData.supplierEmail.trim() === "" || productData.supplierTel.trim() === "" || productData.assetName.trim() === "" || productData.assetPrice < 1 || productData.assetInfo.trim() === "" || productData.assetCity.trim() === "") {
      setErr("Fill all fields");
      setIsUploading(false);
      return;
    }
    if (productData.assetMandatoryImage.length === 0) {
      setErr("Add Mandatory Image please!");
      setIsUploading(false);
      return;
    }

    const formData = new FormData()
    formData.append('assetMandatoryImage', productData.assetMandatoryImage);
    formData.append('supplierFirstName', productData.supplierFirstName);
    formData.append('supplierOtherNames', productData.supplierOtherNames);
    formData.append('supplierEmail', productData.supplierEmail);
    formData.append('supplierTel', productData.supplierTel);
    formData.append('assetType', productData.assetType);
    formData.append('assetName', productData.assetName);
    formData.append('assetPrice', productData.assetPrice);
    formData.append('assetCurrency', productData.assetCurrency);
    formData.append('assetInfo', productData.assetInfo);
    formData.append('assetCountry', productData.assetCountry);
    formData.append('assetCity', productData.assetCity);
    formData.append('assetUserId', productData.assetUserId);
    //call API
    axios.defaults.withCredentials = true;
    if(isUpdate)
    {
      formData.append('oldItemId', oldItemId);
      axios.post("https://wl.widelogistics.online/api/update", formData)
      .then(res => {
        if (res.data.ResultStatus) {
          navigate('/user?assetUpdated=true')
        }
        else {
          setErr(res.data.ResultMsg)
        }
      })
      .catch(err => { setErr("Error: " + err) })
    }
    else
    {
      axios.post("https://wl.widelogistics.online/api/upload", formData)
      .then(res => {
        if (res.data.ResultStatus) {
          navigate('/user?assetAdded=true')
        }
        else {
          setErr(res.data.ResultMsg)
        }
      })
      .catch(err => { setErr("Error: " + err) })
    }
    
  }

  const handleMandatoryFileChange = (event) => {
    if (event.target.files.length > 0) {
      setProductData({ ...productData, assetMandatoryImage: event.target.files[0] })
    }
  };

  const handleAdditionalFileChange = (event) => {
    if (event.target.files.length > 0) {
      setProductData({ ...productData, assetAdditionalImage: Array.from(event.target.files) })
    }
  };

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get("https://wl.widelogistics.online/api/get-user-id")
      .then(res => {
        if (res.data.ResultStatus) {
          setProductData({ ...productData, assetUserId: res.data.ResultMsg })
        }
        else {
          setErr(res.data.ResultMsg)
          navigate('/user?msg=not-logged-in')
        }
      })
      .catch(err => { setErr("Error: " + err) })
  }, [])

  useEffect(()=>
  {
    if(isUpdate)
    {
      axios.get(`https://wl.widelogistics.online/api/products/id/${oldItemId}`)
      .then(res => {
        if (res.data.ResultStatus) {
          if (res.data.ResultMsg.length > 0) {
            const newProduct = res.data.ResultMsg[0];
            setProductData({
              ...productData,
              supplierFirstName: newProduct.supplierFirstName,
              supplierOtherNames: newProduct.supplierOtherNames,
              supplierEmail: newProduct.supplierEmail,
              supplierTel: newProduct.supplierTel,
              assetType: newProduct.assetType,
              assetName: newProduct.assetName,
              assetPrice: newProduct.assetPrice,
              assetCurrency: newProduct.assetCurrency,
              assetInfo: newProduct.assetInfo,
              assetCountry: newProduct.assetCountry,
              assetCity: newProduct.assetCity,
              assetUserId: newProduct.assetUserId
            });

          }
          else {
            console.log("No Product")
          }
        }
        else {
          console.log(res.data.ResultMsg)
        }
      })
      .catch(err => console.log(err));
    }
    
  }, [])

  return (
    <div className="upload-body">
      <div className="upload-header">
        <span className="page_title"><i className="fa fa-file"></i> Upload Products</span> <br />
        <span className='error-container' style={{ color: 'red' }}>{err && err}</span>
      </div>
      <div>
        <form className="upload-form" onSubmit={handleUpload}>

          <div>
            <ul>
              <h3> <i className="fa fa-info-circle"></i> OWNER DATA:</h3>
              <li><input type="text" name="firstname" id="firstname" placeholder="first name" value={productData.supplierFirstName} onChange={(e) => { setProductData({ ...productData, supplierFirstName: e.target.value }) }} /></li>
              <li><input type="text" name="othernames" id="" placeholder="other names" onChange={(e) => { setProductData({ ...productData, supplierOtherNames: e.target.value }) }} value={productData.supplierOtherNames}/></li>
              <li> <input type="text" name="email" id="email" placeholder="email" onChange={(e) => { setProductData({ ...productData, supplierEmail: e.target.value }) }} value={productData.supplierEmail} /></li>
              <li><input type="tel" name="tel" id="tel" placeholder="tel" onChange={(e) => { setProductData({ ...productData, supplierTel: e.target.value }) }} value={productData.supplierTel} /></li>
            </ul>
          </div>
          <div>
            <ul>
              <h3><i className="fa fa-file"></i> Asset info</h3>
              <li>
                <label htmlFor="assetType"> Choose asset type:</label> <br />
                <select name="assetType" id="assetTtype" onChange={(e) => { setProductData({ ...productData, assetType: e.target.value }) }} value={productData.assetType}>
                  <option value="food">Food</option>
                  <option value="house">House</option>
                  <option value="land">Land</option>
                  <option value="cloth">Cloth</option>
                  <option value="equipment">Electronic</option>
                  <option value="equipment">Equipment</option>
                  <option value="room">Living room</option>
                  <option value="room">Apartment</option>
                </select>
              </li>
              <li><input type="text" name="assetName" id="assetName" placeholder="asset Name" onChange={(e) => { setProductData({ ...productData, assetName: e.target.value }) }} value={productData.assetName} /></li>
              <li><input type="number" name="price" id="price" placeholder="price" onChange={(e) => { setProductData({ ...productData, assetPrice: e.target.value }) }} value={productData.assetPrice} /></li>
              <li>
                <label htmlFor="currency">choose currency:</label>
                <select name="currency" id="currency" onChange={(e) => { setProductData({ ...productData, assetCurrency: e.target.value }) }} value={productData.assetCurrency}>
                  <option value="RWF">Rwf</option>
                  <option value="$">$</option>
                </select>
              </li>
              <li>
                <label htmlFor="additionalinfo"> addition Information</label> <br />
                <textarea name="additionalinfo" id="additionalinfo" cols="20" rows="3" placeholder="add more description" value={productData.assetInfo} onChange={(e) => { setProductData({ ...productData, assetInfo: e.target.value }) }}></textarea>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <h3><i className="fa fa-solid fa-image"></i> upload photo</h3>
              <div className="upload-container">
                <label className="upload-label" htmlFor="mandatory_image">Choose Mandatory Image</label>
                <input type="file" className="upload-input" name="mandatory_image" id="mandatory_image" onChange={handleMandatoryFileChange} />
                <p className="upload-text">Selected File: {productData.assetMandatoryImage ? productData.assetMandatoryImage.name : 'No file chosen'}</p>
              </div>
              <div className="upload-container">
                <label className="upload-label" htmlFor="additional_image">Choose Additional Images</label>
                <input type="file" className="upload-input" name="additional_image" id="additional_image" onChange={handleAdditionalFileChange} multiple />
                <p className="upload-text">Selected Files: {productData.assetAdditionalImage.length === 0 ? 'No files chosen' : productData.assetAdditionalImage.map(file => file.name).join(', ')}</p>
              </div>
            </ul>
          </div>
          <div>
            <ul>
              <li><h3> <i className="fa fa-location-arrow"></i> Add location</h3></li>
              <li>
                <label htmlFor="location">choose where product located</label> <br />
                <select name="location" id="location" value={productData.assetCountry} onChange={(e) => { setProductData({ ...productData, assetCountry: e.target.value }) }}>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Tanzania">Tanzania</option>
                  <option value="Burundi">Burundi</option>
                  <option value="DRC"> DR CONGO</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Gabon">Gabon</option>
                </select>
              </li>
              <li>
                <label htmlFor="city">Enter city:</label> <br />
                <input type="text" name="city" id="city" placeholder="city" onChange={(e) => { setProductData({ ...productData, assetCity: e.target.value }) }} value={productData.assetCity}/>
              </li>
            </ul>
          </div>
          <button disabled={isUploading}>{isUploading ? "Uploading..." : "Upload Before Pay"}</button>
          <button onClick={() => { navigate("/pay") }} className="mx-2">PAY</button>      </form>
      </div>
    </div>
  );
}

export default Upload;
