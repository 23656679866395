import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './Search.css'

function SearchNav() {
  const [searchText, setSearchText] = useState("")
  const navigate = useNavigate()

  function handleSearch()
  {
    if(searchText === "")
    {
      return;
    }
    navigate(`/search?search=${searchText}`)
  }
  return (
    <div className="header-search">
      <div className="logo-name">Wide Logistics And Online Service</div>
      <div className="search-bar">
        <input type="text" placeholder="Search for products" value={searchText} onChange={(e)=>{setSearchText(e.target.value)}} />
        <button className="search-button" onClick={handleSearch}>Search</button>
      </div>
    </div>
  );
}

export default SearchNav
