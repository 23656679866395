import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { checkLogin } from '../assets/AdminApi'
import { useNavigate } from 'react-router-dom'

const Users = () => {
    const [users, setUsers] = useState([])


    axios.defaults.withCredentials = true
    const navigate = useNavigate()
    useEffect(() => {
      const checkUserLogin = async () => {
        const isLoggedIn = await checkLogin();
        if (!isLoggedIn) {
          navigate('/AdminLogin');
        }
      };
      checkUserLogin();
    }, [navigate]);


    useEffect(()=>{
        //fetch all  users
        const linkToAPI = `https://wl.widelogistics.online/api/admin/users`;
        axios.get(linkToAPI)
            .then(res=>
                {
                    if(res.data.ResultStatus)
                    {
                        setUsers(res.data.ResultMsg)
                        console.log(res.data.ResultMsg)
                      
                    }
                    else
                    {
                      console.log('Error in Fetching Users ' + res.data.ResultMsg);
                    }
                })
            .catch(err => console.log(err));
      }, [])
  return (
    <div style={{padding: 50}} >
      <h1>ALL USERS</h1>

      <table class="table table-hover table-striped ">
        <thead>
            <tr>
                <th scope="col">User Names</th>
                <th scope="col">User Email</th>
                <th scope="col">User Password</th>
                <th scope="col">User Phone</th>
            </tr>
        </thead>
        <tbody>
          {users && users.map(user => <User userName={user.user_first_name} lastName={user.user_other_names} userEmail={user.user_email} userPassword={user.user_password} userPhone={user.user_phone}/>)} 
        </tbody>
    </table>

    </div>
  )
}

function User({userName, lastName, userEmail, userPassword, userPhone})
{
    return (
        <tr>
          <td>{userName + " " + lastName}</td>
          <td>{userEmail}</td>
          <td>{userPassword}</td>
          <td>{userPhone}</td>
        </tr>
    )
}

export default Users
