import React, { useState } from 'react'
import './ContactStyle.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Contact = () => {


  const notify = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };

  const notifyErr = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };
  const navigate = useNavigate()
  const mapStyle = {
    border: '0'
  };

  const [contactData, setContactData] = useState({
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    contactMessage: ''
  })
  const [contactErr, setContactErr] = useState()

  function handleContactSubmit(e)
  {
    e.preventDefault()
    if(contactData.contactName.length === 0 || contactData.contactEmail.length === 0 || contactData.contactPhone.length === 0 || contactData.contactMessage.length === 0)
    {
      setContactErr('Please fill all the fields')
      return;
    }

    axios.post("https://wl.widelogistics.online/api/contact/add", contactData)
    .then(res => {
      if(res.data.ResultStatus)
      {
        notify("Contact Submitted!")
        setContactData({
          contactName: '',
          contactEmail: '',
          contactPhone: '',
          contactMessage: ''
        })
      }
      else
      {
        setContactErr(res.data.ResultMsg)
      }
    })
    .catch(err => { notifyErr(err); setContactErr("Error: " + err)})

  }

  return (
    <div>
        <section id="Contact">
          <div class="contact-header">
              <h2>Contact Us</h2>
          </div>

          <div class="contact-section">

              <div class="map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.136448185978!2d30.10866481475527!3d-1.9623978991713035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1840b8e8e0d55ac3%3A0xc7d789456bde1b38!2sGikondo%2C%20Kigali!5e0!3m2!1sen!2srw!4v1566907212382!5m2!1sen!2srw"  width="100%" height="450px" frameborder="0" style={mapStyle} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
              </div>

              <div class="contact-form">
                  {contactErr && <span className='text-danger'>{contactErr}</span>}
                  <form onSubmit={(e)=> handleContactSubmit(e)}>
                      <input type="text" id="name" value={contactData.contactName} onChange={(e)=>{setContactData({...contactData, contactName: e.target.value})}} name="contactName"  placeholder="fullname" required/>
                      <input type="email" id="email" value={contactData.contactEmail} onChange={(e)=>{setContactData({...contactData, contactEmail: e.target.value})}} name="contactEmail"  placeholder="email" required/>
                      <input type="number" name="contactPhone" value={contactData.contactPhone} onChange={(e)=>{setContactData({...contactData, contactPhone: e.target.value})}} id="tel" placeholder="phone"/>
                      <textarea id="message" name="contactMessage" value={contactData.contactMessage} onChange={(e)=>{setContactData({...contactData, contactMessage: e.target.value})}} rows="4" placeholder="message" required></textarea>
                      <button type="submit">Send your message</button>
                  </form>
              </div>
          </div>
          
        </section>
    </div>
  )
}

export default Contact
