import { useState } from 'react'
import './App.css'
import Header from './Header'
import Sidebar from './Sidebar'
import { Routes, Route, Outlet } from 'react-router-dom'
import './Inventory.css';

function AppAdmin() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }
  return (
    <div className='grid-container'>
    <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
    <Header OpenSidebar={OpenSidebar}/>
    <Outlet />
    </div>
  )
}

export default AppAdmin
