import React, { useContext, useRef, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './Nav.css';
import wikilix_logo from './assets/wkilix_logo.jpeg';
import flag_rwanda from './assets/rwanda.png'
import flag_burundi from './assets/burundi.jpg'
import flag_DRC from './assets/DRC.png'
import flag_kenya from './assets/kenya.png'
import flag_tanzania from './assets/tanzania.jpg'
import flag_uganda from './assets/uganda.png'
import flag_gabon from './assets/gabon.png'
import { Link, useNavigate } from 'react-router-dom'
import SearchNav from './navComponents/SearchNav';
import { WikilixContext } from '../../WikilixContextProvider';
import axios from 'axios';
import NavUser from './navComponents/NavUser';

function NavBar() {

  const navigate = useNavigate()

  const countryImage = useRef();
  const countrySelection = useRef();
  const { currentCountry, setCurrentCountry } = useContext(WikilixContext);
  const { isUserLoggedIn, setIsUserLoggedIn } = useContext(WikilixContext);
  const { loggedInUser, setLoggedInUser } = useContext(WikilixContext);

  function changeCountryImg() {
    setCurrentCountry(countrySelection.current.value);
    if (countrySelection.current.value === "Rwanda") {
      countryImage.current.src = flag_rwanda;
    }
    else if (countrySelection.current.value === "Burundi") {
      countryImage.current.src = flag_burundi;
    }
    else if (countrySelection.current.value === "Kenya") {
      countryImage.current.src = flag_kenya;
    }
    else if (countrySelection.current.value === "Tanzania") {
      countryImage.current.src = flag_tanzania;
    }
    else if (countrySelection.current.value === "Uganda") {
      countryImage.current.src = flag_uganda;
    }
    else if (countrySelection.current.value === "DRC") {
      countryImage.current.src = flag_DRC;
    }
    else if (countrySelection.current.value === "Gabon") {
      countryImage.current.src = flag_gabon;
    }
  }

  axios.defaults.withCredentials = true;
  useEffect(() => {
    changeCountryImg()
    axios.get('https://wl.widelogistics.online/api/check-user')
      .then(res => {
        if (res.data.ResultStatus) {
          setIsUserLoggedIn(true);
          setLoggedInUser(res.data.ResultMsg.userName);
        }
        else {
          setIsUserLoggedIn(false);
          setLoggedInUser("");
        }
      })
      .catch(err => console.log(err));
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMenuOpen(!menuOpen);
  }


  return (
    <>



      <div className='navbar'>

        <SearchNav />

        <div className="logo">
          <img src={wikilix_logo} alt="Wide Logistics Logo" />
          <button className="mobile-menu-button" onClick={toggleMobileMenu}>
            {menuOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
          </button>
        </div>
        <div className="menu-barr"></div>
        <div className={`all-menu ${menuOpen ? 'menu-open' : ''}`}>
          <Link to="/">Home</Link>
          <Link to="/about">About Us</Link>
          <div className='dropdown-1'>
            <button className='services'>Service</button>
            <div className='service-content sub-div'>
              <Link to="/shipping">Shipping and Delivery</Link>
              <Link to="/procurement">Procurement</Link>
            </div>
          </div>
          <div className='dropdown-2'>
            <button className='buy'>Buy Products</button>
            <div className='buy-content sub-div'>
              <Link to="/for-buy?cat=food">Food Stuff</Link>
              <Link to="/for-buy?cat=equipment">Equipment</Link>
              <Link to="/for-buy?cat=cloth">Clothes</Link>
            </div>
          </div>
          <div className='dropdown-3'>
            <button className='rent'>For Rent</button>
            <div className='rent-content sub-div'>
              <Link to="/for-rent?cat=room">Living rooms</Link>
              <Link to="/for-rent?cat=room">Lodges rooms</Link>
              <Link to="/for-rent?cat=room">Apartment rooms</Link>
              <Link to="/for-rent?cat=house">Trading house</Link>
            </div>
          </div>
          <div className='dropdown-4'>
            <button className='prop'>Property</button>
            <div className='prop-content sub-div'>
              <Link to="/property?cat=house">House on sale</Link>
              <Link to="/property?cat=land">Land on sale</Link>
              <Link to="/property?cat=equipment">Used equipment</Link>
            </div>
          </div>
          <div className='dropdown-4'>
            <button className='prop'>News</button>
            <div className='prop-content sub-div'>
              <Link to="/news">Announcements</Link>
              <Link to="/events">Events</Link>
              <Link to="/testimonies">Testimonies</Link>
            </div>
          </div>
          <Link to="/contact-us">Contact Us</Link>
        </div>
      </div>

      <div className="login">

        <div className="countries">
          <span ><img ref={countryImage} className="country-image" src={flag_rwanda} /></span>
          <select name="country" ref={countrySelection} id="country" className="country" onChange={() => { changeCountryImg() }} value={currentCountry}>
            <option value="Rwanda"> Rwanda</option>
            <option value="Burundi"> Burundi</option>
            <option value="Kenya"> Kenya</option>
            <option value="Tanzania"> Tanzania</option>
            <option value="Uganda"> Uganda</option>
            <option value="Gabon"> Gabon</option>
            <option value="DRC"> DRC</option>
          </select>
        </div>

        <div id="wiki">
          <strong>Wide Logistics And Online Services <br /><span id='country-welcome'>What do you need in {currentCountry.toUpperCase()}?</span> </strong>
        </div>

        <div className="login-section">
          <NavUser isLoggedIn={isUserLoggedIn} loggedInUserNames={loggedInUser} />
        </div>
      </div>

    </>
  )
}

export default NavBar