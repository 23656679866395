import React, { useEffect, useState } from 'react'
import './HomeStyle.css'
import slide1 from './assets/slide1.jpg'
import slide2 from './assets/slide2.png'
import slide3 from './assets/slide3.png'
import slide4 from './assets/slide4.png'
import Contact from '../contact/Contact'

const Home = () => {

  var slideIndex = 1;
  window.onload = function() {
    showSlide(slideIndex);
  };
  
    function changeSlide(n) {
      showSlide(slideIndex += n);
    }

    setInterval(()=>{changeSlide(1)}, 4000);

    function showSlide(n) {
      var slides = document.getElementsByClassName("slideshow-image");

      if (n > slides.length) {
        slideIndex = 1;
      } 
      if (n < 1) {
        slideIndex = slides.length;
      }

      for (var i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
        slides[i].style.display = "none";
      }

      slides[slideIndex - 1].style.display = "block";
    }

  return (
    <div>
        <div class="slideshow-container">
            <img class="slideshow-image" src={slide1} alt="Image 1"/>
            <img class="slideshow-image" src={slide2} alt="Image 1"/>
            <img class="slideshow-image" src={slide3} alt="Image 1"/>
            <img class="slideshow-image" src={slide4} alt="Image 3"/>
            <a class="prev" onClick={()=>{changeSlide(-1)}}>&#10094;</a>
            <a class="next" onClick={()=>{changeSlide(1)}}>&#10095;</a>
        </div>

        

        <Contact />
      

    </div>
  )
}

export default Home
