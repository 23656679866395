import React, { useEffect, useState } from 'react'
import './AboutStyle.css'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css'
import './AboutUsTeamStyle.css'
import TeamMember from './TeamMember'

const About = () => {

    const [team, setTeam] = useState([])
    useEffect(() => {
        const linkToAPI = `https://wl.widelogistics.online/api/team`;
        axios.get(linkToAPI)
            .then(res => {
                if (res.data.ResultStatus) {
                    setTeam(res.data.ResultMsg)
                }
                else {
                    console.log('Error in Fetching Team: ' + res.data.ResultMsg);
                }
            })
            .catch(err => console.log(err));
    }, [])


    return (
        <div className='about-container'>
            <div class="all-items">
                <div class="first-div">
                    <div class="Membership">Mission:</div>
                    <div class="member-title member-title-mission">
                        <p>
                            our mission is a quickly connecting and linking the people who are searching services with other people who are providing the services globally.
                        </p>
                    </div>
                </div>
                <div class="first-div">
                    <div class="Membership">Vision:</div>
                    <div class="member-title member-title-vision">
                        <p>
                            Our vision is to become the leading online quick services access platform worldwide, by innovating the way people search rental properties, buying and selling products, consulting and shipping cargos across the World.</p>
                    </div>
                </div>
                <div class="second-div">
                    <div class="History">History:</div>
                    <div class="History-title">
                        <p>Wide Logisticsis a platform or a portal has designed and developed to facilitate communities to get access on some services like rental properties, trade transactions mainly for used equipment. This has initiated in order to solve time waste and anxiety for looking and waiting services.
                            Most of the people they change living locations due to different factors so, by this to make sure for getting where they will get rents its become a big issue to deal with, that is why Wide Logistics has brought a solution for this problem where people from worldwide should visit this portal to search better rent that is comfortable  for them. Not only rents but also always we need some equipment like bedrooms equipment, kitchen equipment, home equipment and others. and it is not always we got ability to buy new, occasionally we need used equipment or secondhand equipment. This is a portal that will enable persons with that equipment to post and enable those persons that is need them to get it easily and quickly.
                        </p>
                    </div>
                </div>
            </div>
            <div className='team-container mt-4'><h1>OUR TEAM</h1>

                <div class="row my-5">

                {team && team.map(team1 => <TeamMember teamName={team1.team_name} teamUserImg={team1.team_userImg} teamRole={team1.team_role} teamEmail={team1.team_email} teamWhatsapp={team1.team_whatsapp} teamInstagram={team1.team_instagram} teamPhone={team1.team_phone} teamFacebook={team1.team_facebook}/>)}

                </div>

            </div>
        </div>
    )
}

export default About
