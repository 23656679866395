import { useState, useEffect } from 'react';

// Custom hook for local state with local storage
function useLocalState(key, initialValue) {
  // Retrieve the value from local storage (if available)
  const storedValue = localStorage.getItem(key);

  // Initialize the state with the stored value or the initial value
  const [state, setState] = useState(storedValue ? JSON.parse(storedValue) : initialValue);

  // Use useEffect to store the state in local storage whenever it changes
  useEffect(() => {
    // Convert the state value to a string and store it in local storage
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

export default useLocalState;
