import React from 'react';
import './services.css';
import img1 from'./img/procurements.jpg';
import img2 from'./img/procure.png';
function ProcurementPage() {
  return (
    <div>
      <div className="header">
        <h3>Procurement Consultancy Services</h3>
        <h6>
          We are professional specialists in procurement where we help our clients to empower organizations with strategic procurement solutions, fostering efficiency, sustainability, and profitability while maintaining ethical and transparent practices.
        </h6>
      </div>

      <div className="service-container">
        <div className="service">
          <div className="service-text">
            <img src={img1}/>
            <p className="service-description">We offer different services including Procurement process optimization, evaluation, suppliers’ selections, contract execution, and contract management.</p>
          </div>
        </div>
      </div>

      <div className="service-container">
        <div className="service">
          <div className="service-text">
            <img src={img2} />
            <p className="service-description">Supporting suppliers/bidders to study and analyze global tenders and we connect with eligible suppliers. We provide coaching and training to bidders and newborn bidders that enable them to have the capacity to compete on existing projects.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcurementPage;
