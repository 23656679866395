import axios from 'axios';

export const checkLogin = async () => {
  try {
    const response = await axios.get('https://wl.widelogistics.online/api/admin/checkLogin');
    return response.data.ResultStatus;
  } catch (error) {
    console.error('Error checking login:', error);
    return false;
  }
};
