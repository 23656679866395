import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { checkLogin } from '../assets/AdminApi'
import { useNavigate } from 'react-router-dom'

const AllContacts = () => {
    const [contacts, setContacts] = useState([])
    axios.defaults.withCredentials = true

    const navigate = useNavigate()
    useEffect(() => {
      const checkUserLogin = async () => {
        const isLoggedIn = await checkLogin();
        if (!isLoggedIn) {
          navigate('/AdminLogin');
        }
      };
      checkUserLogin();
    }, [navigate]);


    useEffect(()=>{
        //fetch all  users
        const linkToAPI = `https://wl.widelogistics.online/api/admin/contacts`;
        axios.get(linkToAPI)
            .then(res=>
                {
                    if(res.data.ResultStatus)
                    {
                        setContacts(res.data.ResultMsg)
                    }
                    else
                    {
                      console.log('Error in Fetching Users ' + res.data.ResultMsg);
                    }
                })
            .catch(err => console.log(err));
      }, [])
  return (
    <div style={{padding: 50}} >
      <h1>ALL SUGGESTIONS</h1>

      <table class="table table-hover table-striped ">
        <thead>
            <tr>
                <th scope="col">Names</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Message</th>
            </tr>
        </thead>
        <tbody>
          {contacts && contacts.map(contact => <Contact contactName={contact.contact_name} contactEmail={contact.contact_email} contactPhone={contact.contact_phone} contactMessage={contact.contact_message}/>)} 
        </tbody>
    </table>

    </div>
  )
}

function Contact({contactName, contactEmail, contactPhone, contactMessage})
{
    return (
        <tr>
          <td>{contactName}</td>
          <td>{contactEmail}</td>
          <td>{contactPhone}</td>
          <td>{contactMessage}</td>
        </tr>
    )
}

export default AllContacts
