import React, { useEffect, useState } from 'react'
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, BsFillBellFill }
  from 'react-icons/bs'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line }
  from 'recharts';
  import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function HomeAdmin() {

  axios.defaults.withCredentials = true
  const navigate = useNavigate()
  useEffect(()=>{
    axios.get("https://wl.widelogistics.online/api/admin/checkLogin")
      .then(res => {
        if(!res.data.ResultStatus)
        {
          navigate('/AdminLogin');
        }
      })
      .catch(err => {
        console.log(err);
      })
    }, [navigate])


  const [allData, setAllData] = useState({})
    axios.defaults.withCredentials = true
    useEffect(()=>{
        //fetch all  users
        const linkToAPI = `https://wl.widelogistics.online/api/admin/count`;
        axios.get(linkToAPI)
            .then(res=>
                {
                    if(res.data.ResultStatus)
                    {
                      setAllData(res.data.ResultMsg)
                      console.log(res.data.ResultMsg)
                      
                    }
                    else
                    {
                      console.log('Error in Fetching Data ' + res.data.ResultMsg);
                    }
                })
            .catch(err => console.log(err));
      }, [])

  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];


  return (
    <main className=''>

      {/* <div className='main-title'>
        <h3>DASHBOARD</h3>
      </div> */}

      <div className='d-flex'>

        <div className="card mx-5" style={{width: '20rem', height: 'fit-content'}}>
          <img className="card-img-top" src=""/>
          <div className="card-body text-center">
            <h1>PRODUCTS</h1>
            <p className="card-text text-white mt-3">Total Products Uploaded</p>
            <h1 className='text-center mt-5'>{allData && allData.allProducts}</h1>
          </div>
        </div>

        <div className="card" style={{width: '20rem', height: 'fit-content'}}>
          <img className="card-img-top" src=""/>
          <div className="card-body text-center">
            <h1>USERS</h1>
            <p className="card-text text-white mt-3">Total Users Registered</p>
            <h1 className='text-center mt-5'>{allData && allData.allUsers}</h1>
          </div>
        </div>
      </div>

      {/* <div className='charts'>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="pv" fill="#8884d8" />
            <Bar dataKey="uv" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>

        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>

      </div> */}

    </main>
  )
}

export default HomeAdmin