import React, { useState } from 'react';
import Items from '../../items/Items';
import EmptyPlaceHolder from '../../Other/EmptyPlaceHolder';
import EmptyProducts from '../../Other/EmptyProducts';

const UserProducts = ({ products, error, deleteItem }) => {
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(products.length / itemsPerPage);

  const generatePaginationButtons = () => {
    const maxButtonsToShow = 5; // Change this value to adjust the number of buttons to show

    const buttons = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > maxButtonsToShow) {
      const halfMaxButtons = Math.floor(maxButtonsToShow / 2);

      if (currentPage <= halfMaxButtons) {
        endPage = maxButtonsToShow;
      } else if (currentPage >= totalPages - halfMaxButtons) {
        startPage = totalPages - maxButtonsToShow + 1;
      } else {
        startPage = currentPage - halfMaxButtons;
        endPage = currentPage + halfMaxButtons;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? 'active' : ''}`}
          onClick={() => paginate(i)}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="left user-div">
      {currentProducts.length > 0 && <Items deleteItem={deleteItem} products={currentProducts} productCategory="a" page="User" />}
      {currentProducts.length < 1 && <EmptyProducts />}
      <EmptyPlaceHolder />

      {products.length > itemsPerPage && (
        <div className="pagination">
          <button
            className={`page-button ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={() => paginate(currentPage - 1)}
          >
            Previous
          </button>
          {generatePaginationButtons()}
          <button
            className={`page-button ${currentPage === totalPages ? 'disabled' : ''}`}
            onClick={() => paginate(currentPage + 1)}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default UserProducts;
