import React, { useEffect, useState } from 'react';
import './testmolial.css'; // Make sure to import your CSS file
import user1 from './img/user.png';
import user2 from './img/user1.jpeg';
import axios
 from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalf } from '@fortawesome/free-regular-svg-icons';
function TestimonialsPage() {

  const [testimonies, setTestimonies] = useState([])
  useEffect(()=>{
    axios.get('https://wl.widelogistics.online/api/testimonies').then(res => {
      if(res.data.ResultStatus)
      {
        setTestimonies(res.data.ResultMsg)
      }
      else
      {
        console.log(res.data.ResultMsg)
      }
    })
  }, [])

  return (
    <div className="testmolia-container">
      <h2>Discover What Our Valued Customers Have to Say</h2>
      <hr />

      {testimonies && testimonies.map( testimony => <Testimony testimonyData = {testimony}/>)}
      
      
     
      {/* Add more testimonials as needed */}
    </div>
  );
}

function Testimony({testimonyData}){
  function stars(activeStars)
  {
    let allStars = [];
    for(let i = 1; i <= 5; i++)
    {
      if(i <= activeStars)
      {
        allStars += "★";
      }
      else
      {
        allStars += "☆";
      }
    }
    return allStars
  }
  const imgUrl = `https://wl.widelogistics.online/api/asset_images/${testimonyData.testimony_img}`;
  return(
      <div className="testimonial">
        <img src={imgUrl}alt="User 1" />
        <h2>{testimonyData.testimony_name}</h2>
        <p>
        {testimonyData.testimony_desc}
        </p>
        <div className="stars">{stars(testimonyData.testimony_stars)}</div>
      </div>
  )
}

export default TestimonialsPage;
