import React from 'react'

const EmptyProducts = () => {
  return (
    <div>
      <p style={{color: 'Red'}}>Oops! No Products Found!</p>
    </div>
  )
}

export default EmptyProducts
