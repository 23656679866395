import React, { useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import '../../assets/ProductsStyle.css'
import Items from '../../items/Items'
import axios from 'axios'
import { WikilixContext } from '../../../WikilixContextProvider';
import Item from '../../item/Item'
import EmptyProducts from '../../Other/EmptyProducts'



const ForBuy = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const forBuyCategory = urlParams.get('cat');
  const { currentCountry, setCurrentCountry } = useContext(WikilixContext);

  const [products, setProducts] = useState([]);
  const [isProductsEmpty, setIsProductEmpty] = useState(false)

  useEffect(()=>{
    //fetch all  products using forBuyCategory
    const linkToAPI = `https://wl.widelogistics.online/api/products/category/${forBuyCategory}/${currentCountry}`;
    axios.get(linkToAPI)
        .then(res=>
            {
                if(res.data.ResultStatus)
                {
                  if(res.data.ResultMsg.length === 0)
                  {
                    setIsProductEmpty = true
                  }
                  else
                  {
                    setProducts(res.data.ResultMsg)
                  }
                  
                }
                else
                {
                  console.log('Error in Fetching Products' + res.data.ResultMsg);
                }
            })
        .catch(err => console.log(err));
  }, [currentCountry])
  return (
    <div className='main-container'>
        <div className='for-buy-container'>
        <center>

            { products && <Items products={products} productCategory={forBuyCategory} page="buy"/>}
            { isProductsEmpty && <EmptyProducts/> }


            <div className='houses-for-sell for-sell'>
                <div className='for-sell-title'>
                  <h1 style={{color: 'white'}}>.</h1>
                </div>
            </div>

        </center>
        </div>
    </div>
  )
}

export default ForBuy