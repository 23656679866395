import React, { useState } from 'react'
import './ItemStyle.css'
import { Link, useNavigate } from 'react-router-dom';

const Item = (props) => {

    const navigate = useNavigate();
    const itemData = props.itemData;
    const pageName = props.pageName;
    const deleteItem = props.deleteItem;

    function deleteProduct()
    {
        const confirmed = window.confirm("Are you sure you want to delete this Product?");
        if(confirmed)
        {
            deleteItem(itemData.asset_id)
        }
    }
    function manageProduct()
    {
        navigate(`/upload?item=${itemData.asset_id}`)
    }
  
    const imgUrl = `https://wl.widelogistics.online/api/asset_images/${itemData.assetMandatoryImage}`;
    return (
        <div className='item-container'>
            <img src={imgUrl} className='item-img' />
            <p>{itemData.assetName}</p>
            <p>Price: {itemData.assetPrice} {itemData.assetCurrency}</p>
            <p>Country: {itemData.assetCountry}</p>
            <p>By: {itemData.supplierFirstName}</p>
            {/* <p className='link-container'><a onClick={() => {toogleShopStatus()}} className='shop-link'>{shopStatus}</a></p> */}
            {pageName != "User" && <p className='link-container'><Link to={`https://wa.me/25${itemData.supplierTel}?text=Hello%20${itemData.supplierFirstName},%20I%20need%20to%20buy%20${itemData.assetName}%20on%20Wide%20Logistics%20Website`} target="_blank" className='shop-link'>Shop Via WhatsApp</Link></p>}
            {pageName === "User" && <p className='d-flex justify-content-center'>
                <button className='me-2' onClick={manageProduct}>Manage</button>
                <button className='btn btn-danger' onClick={deleteProduct}>Delete</button>
            </p>}
        </div>
    )
}


export default Item
