import React, { useEffect } from 'react'
import './FooterStyle.css'
import 'font-awesome/css/font-awesome.min.css';
import { FaFacebook, FaTwitter, FaInstagram, FaPinterest, FaTiktok, FaYoutube, FaEnvelope, FaPhone, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import $ from 'jquery';

const Footer = () => {

  useEffect(() => {
    $('[title="Hosted on free web hosting 000webhost.com. Host your own website for FREE."]').hide();
  }, []);

  return (
    <footer>
      <div className="footer-container">
        <div className="footer-links">
          <div>
            <h2>Wide Logistics And Online Services</h2>
            <p>Wide Logistics And Online Services: A secure, user-friendly e-commerce platform with diverse products, trusted vendors, and convenient shopping for customers and businesses.</p>
          </div>
          <div>
            <h3>Quick Links</h3>
            <Link to="/">Home</Link>
            <Link to="about">About us</Link>
            <Link to="services">Services</Link>
            <Link to="for-buy">For Buy</Link>
            <Link to="for-rent">For Rent</Link>
            <Link to="property">Property</Link>
            <Link to="news">News</Link>
            <Link to="contact-us">Contact Us</Link>
          </div>
          <div>
            <h3>Privacy & Terms</h3>
            <Link to="terms">Terms & Conditions</Link>
            <Link to="AdminLogin">Admin</Link>
          </div>
          <div>
            <h3>Contact Us</h3>
            <div className="social-icons">
              <Link to="https://www.facebook.com/home.php?ref=wizard"><FaFacebook /></Link>
              <Link to="mailto:info@crprogram.wikilix.com"><FaEnvelope /></Link>
              <Link to="tel:+250790704933"><FaPhone /></Link>
              <Link to="https://wa.me/250790704933"><FaWhatsapp /></Link>
              <Link to="https://www.tiktok.com/@zaygote4545?is_from_webapp=1&sender_device=pc"><FaTiktok /></Link>
              <Link to="https://www.youtube.com/channel/UC1heZHrLlX-7Rothrhz99Uw"><FaYoutube /></Link>
              <p>Kigali, Gikondo</p>
            </div>
          </div>

        </div>
        <div className="copy-right">
          &copy; {new Date().getFullYear()} Wide Logistics And E-Commerce. All Rights Reserved.  Developed by <Link to='https://bakamecoders.000webhostapp.com/' target='_blank' id='bakame' title='Bakame Coders'>BAKAME Coders</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer
