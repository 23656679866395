import './AppStyle.css'
import NavBar from "./Components/nav/NavBar";
import { Outlet, Route, Routes } from "react-router-dom";
import Footer from "./Components/footer/Footer";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { WikilixContextProvider } from './WikilixContextProvider';
function App() {
  return (
      <div className="App">
        <WikilixContextProvider>
          <NavBar/>
          <div className="contents-container">
            <Outlet />
            <ToastContainer />
          </div>
        </WikilixContextProvider>
        <Footer/>
      </div>
  );
}
export default App;
