import React from 'react';
import './services.css';
import ocean from'./img/oceans.webp';
import air from'./img/flight.webp';
import land from './img/land-height.jpg';
import warehouse from './img/warehousing.jpg';

function ServicePage() {
  return (
    <div>
      <div className="header">
        <h1>Shipping and Delivery</h1>
        <h2>We are the one leading global shipping and delivering of the products abroad to customers worldwide.</h2>
      </div>

      <div className="service-container">
        <div className="service">
          <h3 className="service-title">Ocean and Seas Freight</h3>
          <div className="service-text">
            <img src={ocean} />
            <p className="service-description">We facilitate the importers and exporters in delivery and transported products from abroad. We have storage solution for you Transported products from abroad. We have storage solution for you</p>
          </div>
        </div>
      </div>

      <div className="service-container">
        <div className="service">
          <h3 className="service-title">Air Freight</h3>
          <div className="service-text">
            <img src={air}/>
            <p className="service-description">We facilitate the importers and exporters of various products from different countries We have storage solution for you</p>
          </div>
        </div>
      </div>

      <div className="service-container">
        <div className="service">
          <h3 className="service-title">Land Freight</h3>
          <div className="service-text">
            <img src={land}/>
            <p className="service-description">We offer land transport and distribution services across the world.</p>
          </div>
        </div>
      </div>
      <div className="service-container">
        <div className="service">
          <h3 className="service-title">Warehousing and Custom Services</h3>
          <div className="service-text">
            <img src={warehouse}/>
            <p className="service-description">Trade Services rely on extensive experience in facilitating trade, to develop and deliver new Total Quality Assured services for governments, customs, authorities, and standards organizations</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicePage;
