import React, { useEffect, useState } from 'react';
import './events.css'; // Make sure to import your CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendarAlt, faClock } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'; // Import the map marker icon from the solid style icons
import event1 from './img/event.jpeg';
import event2 from './img/events.png';
import axios from 'axios';

function EventsPage() {
  const [events, setEvents] = useState([])
  useEffect(()=>{
    axios.get('https://wl.widelogistics.online/api/events').then(res => {
      if(res.data.ResultStatus)
      {
        setEvents(res.data.ResultMsg)
      }
      else
      {
        console.log(res.data.ResultMsg)
      }
    })
  }, [])
 
  return (
    <div className="event-container">
      <h1>Upcoming Events</h1>

      {events && events.map( event => <Event eventData={event}/>)}

    </div>
  )
}

function Event(props){
  const imgUrl = `https://wl.widelogistics.online/api/asset_images/${props.eventData.event_img}`;
  return(
    <div className="event">
      <img src={imgUrl} alt="Event 1" className="event-image" />
      <h2>{props.eventData.event_title}</h2>
      <div className="info">
        <FontAwesomeIcon icon={faCalendarAlt} className="icon" /><span className="event-date">Date:</span> {props.eventData.event_date}<br />
        <FontAwesomeIcon icon={faClock} className="icon" /><span className="event-time">Time:</span> {props.eventData.event_time}<br />
        <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" /><span className="event-location">Location:</span> {props.eventData.event_location}<br />
        <p>{props.eventData.event_desc}</p>
      </div>
    </div>
  )
}

export default EventsPage;
