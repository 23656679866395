import React, { useEffect, useState} from 'react';
import './login.css';
import user_img from './assets/user.jpg'
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom'

const Login = () => {

  const navigate = useNavigate()
  axios.defaults.withCredentials = true
  useEffect(()=>{
    axios.get("https://wl.widelogistics.online/api/admin/checkLogin")
      .then(res => {
        if(res.data.ResultStatus)
        {
          navigate('/admin');
        }
      })
      .catch(err => {
        console.log(err);
      })
    }, [])

  const [showPopup, setShowPopup] = useState(true);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      {showPopup && (
        <div className="popup-container" id="popupContainer">
          <div className="popup-content">
            <div className="tabs">
              <button className="close-button" id="closePopup" onClick={closePopup}>
                &#10005;
              </button>
              <button className="tab-button active">Login</button>
            </div>
              <LoginForm />
          </div>
        </div>
      )}
    </div>
  );
};

function LoginForm() {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: "",
    password: ""
  });
  const [err, setErr] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = (e) =>
  {
    e.preventDefault()
    setIsLoading(true)
    if(loginData.email == "")
    {
      setErr("Enter Email please");
      setIsLoading(false)
      return;
    }
    if(loginData.password == "")
    {
      setErr("Enter Password please");
      setIsLoading(false)
      return;
    }
    axios.post("https://wl.widelogistics.online/api/admin/login", loginData)
    .then(res => {
      setIsLoading(false)
      if(res.data.ResultStatus)
      {
        navigate('/admin');
      }
      else
      {
        setErr(res.data.ResultMsg);
      }
    })
    .catch(err => {
      setIsLoading(false)
      setErr("External Error: " + err)
    })
  }
  return (
    <form className="form" onSubmit={handleLogin}>
      <div className="icon">
        <img src={user_img} alt="User Icon" />
      </div>
      <p className='error-container'>{err && err}</p>
      <h2>Admin Login</h2>
      <input type="text" placeholder="Email" onChange={(e)=>{setLoginData({...loginData, email: e.target.value})}}/>
      <input type="password" placeholder="Password" onChange={(e)=>{setLoginData({...loginData, password: e.target.value})}}/>
      <Link to="javascript:void(0)" className="forgot-password">
        Forgot Password?
      </Link>
      <button className="login-button" disabled={isLoading}>{isLoading? "Logging in..." : "Login"}</button>
    </form>
  );
}

export default Login;
