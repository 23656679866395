import React, { useEffect, useState } from 'react'
import './newstyle.css'
import news_img from './assets/news.png'
import axios from 'axios'

function cuttedTxt(oldText, newSize)
{
  const arrayOfText = oldText.split(' ');
  const firstWords = arrayOfText.slice(0, newSize);
  const newText = firstWords.join(' ') + "...";
  return newText;
}

const News = () => {
  const [news, setNews] = useState([])
  useEffect(()=>{
    //fetch all  products using forBuyCategory
    const linkToAPI = `https://wl.widelogistics.online/api/news`;
    axios.get(linkToAPI)
        .then(res=>
            {
                if(res.data.ResultStatus)
                {
                  setNews(res.data.ResultMsg);
                  console.log(res.data.ResultMsg)
                }
                else
                {
                  console.log('Error in Fetching News' + res.data.ResultMsg);
                }
            })
        .catch(err => console.log(err));
  }, [])
  
  
  return (
    <div className="App">
    <header>
      <h1>Wide Logistics News</h1>
    </header>

    <main>
      <section className="news-article">
        <h2>Latest News</h2>
        {news && <ArticleNew topNew={news[0]}/>}
        {/* {news && <ArticleNew newImg={news[0].post_head_image} newTitle={news[0].post_title} newDesc={news[0].post_content} newDate={news[0].post_date}/>} */}
      </section>

      <section className="news-list">
        <h2>More News</h2>
        <ul>
        {news && news.map(singleNew => <LiNew new={singleNew}/>)}
        </ul>
      </section>
    </main>
  </div>
  )
}

function ArticleNew(props)
{
  const topNew = props.topNew;
  let imgUrl;
  function loadImg()
  {
     imgUrl = `https://wl.widelogistics.online/api/asset_images/${topNew.post_head_image}`;
  }
 
  return (
    <>
    {topNew && loadImg()}
    { topNew && 
      <article>
      <img src={imgUrl} alt="News" />
      <h3>{topNew.post_title}</h3>
      <p>{topNew.post_content}</p>
    </article>}
    </>
  )
}

function LiNew(props)
{
  
  function timestampToUserFriendlyDate(timestamp) {
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return date.toLocaleDateString('en-US', options);
  }

  const [popupCurrentNew, setPopupCurrentNew] = useState()
  const newTitle = props.new.post_title;
  const newDesc = props.new.post_content;
  const newDate = props.new.post_date;
  const imgUrl = `https://wl.widelogistics.online/api/asset_images/${props.new.post_head_image}`;
  const newNewDate = timestampToUserFriendlyDate(newDate)
  const contents = `Title:\n${newTitle}\n\nDescription:\n${newDesc}\n\nDate: ${newNewDate}` ;

  useEffect(()=>{
    {popupCurrentNew && alert(popupCurrentNew)}
    setPopupCurrentNew("")
  }, [popupCurrentNew])

  return(
    <li style={{marginBottom: 50}}>
      <p style={{display: 'flex', marginBottom: 10}}><img src={imgUrl} alt="News" style={{width: 100, marginRight: 10, height: 'fit-content'}}/><h3>{cuttedTxt(newTitle, 15)}</h3></p>
      
      <p>{cuttedTxt(newDesc, 20)}</p>
      <p><a onClick={()=>{setPopupCurrentNew(contents)}} className="read-more">Read more</a></p>
    </li>
  )
}

// function popupNew(props)
// {
//   const singleNew = props.singleNew;
//   return(
//     <div>
//     </div>
//   )
// }

export default News
