import React from 'react'
import { Link } from 'react-router-dom'
import userImage from './assets/user.jpg'

const UserInfo = (props) => {
  return (
    <div className='user-info-container'>
      <center>
        <img src={userImage} className='user-image'/>
        <p className='user-name'>{props.userNames}</p>
        <div className='options'>     
        <Link to="#">Manage credentials</Link>         
        <a className='user-logout-btn' onClick={props.logoutUser}>Logout</a>
        </div>
      </center>
    </div>
  )
}

export default UserInfo
